import Vue from 'vue'
import VueRouter from 'vue-router'

import { getUserId } from '../utils/store'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/award',
        name: 'Award',
        component: () =>
            import ('@/components/NewAward'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: '/login',
        component: () =>
            import ('@/components/Login'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ready/exam',
        name: 'ExamReady',
        component: () =>
            import ('@/components/Student/ExamReady'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ready/lecture',
        name: 'ExamReady',
        component: () =>
            import ('@/components/Student/LectureReady'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/exam',
        name: 'Exam',
        component: () =>
            import ('@/components/Student/StudentExam'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/awardDetail',
        name: 'AwardDetail',
        component: () =>
            import ('@/components/Award/awardDetail'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/transcript',
        name: 'Transcript',
        component: () =>
            import ('@/components/Award/transcript'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/awardExport',
        name: 'AwardExport',
        component: () =>
            import ('@/components/Award/awardExport'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/certificate',
        name: 'Certificate',
        component: () =>
            import ('@/components/Award/certificate'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/home',
        name: 'Home',
        redirect: '/bulletin',
        component: () =>
            import ('@/views/Home.vue'),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: '/announcements',
                name: 'Announcements',
                component: () =>
                    import ('@/components/Announcements'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/paint',
                name: 'Paint',
                component: () =>
                    import ('@/components/Paint'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/studentlist',
                name: 'StudentList',
                component: () =>
                    import ('@/components/Student/List'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/sessionlist',
                name: 'SessionList',
                component: () =>
                    import ('@/components/Student/List/sessionList'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/selectseat',
                name: 'SelectSeat',
                component: () =>
                    import ('@/components/Student/selectSeat'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/teacherlist',
                name: 'TeacherList',
                component: () =>
                    import ('@/components/Teacher/List'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/employeelist',
                name: 'EmployeeList',
                component: () =>
                    import ('@/components/Employee/List'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/peixun',
                name: 'Peixun',
                component: () =>
                    import ('@/components/Peixun'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/peixunadmin',
                name: 'PeixunAdmin',
                component: () =>
                    import ('@/components/PeixunAdmin'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/newaward',
                name: 'newaward',
                component: () =>
                    import ('@/components/Award/newIndex'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/personalinfo',
                name: 'Personalinfo',
                component: () =>
                    import ('@/components/Info'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/message',
                name: 'Message',
                component: () =>
                    import ('@/components/Message'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/examadminlist',
                name: 'ExamAdminList',
                component: () =>
                    import ('@/components/ExamAdminList'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/myevents',
                name: 'MyEvents',
                component: () =>
                    import ('@/components/Events'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/bulletin',
                name: 'Bulletin',
                component: () =>
                    import ('@/components/Bulletin'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/learningmaterials',
                name: 'LearningMaterials',
                component: () =>
                    import ('@/components/LearningMaterials'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/learninghub',
                name: 'LearningHub',
                component: () =>
                    import ('@/components/LearningHub/ClassList.vue'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/learninghubcontent',
                meta: {
                    requiresAuth: true,
                    title: 'LearningHub'
                },
                component: () =>
                    import ('@/components/LearningHub/StudentExam.vue'),
            },
            {
                path: '/collection-list',
                meta: {
                    requiresAuth: true,
                    title: 'CollectionList'
                },
                component: () =>
                    import ('@/components/LearningHub/CollectionList.vue'),
            },
            {
                path: '/errors',
                meta: {
                    requiresAuth: true,
                    title: 'ErrorList'
                },
                component: () =>
                    import ('@/components/LearningHub/ErrorList.vue'),
            },

            {
                path: "verify-pass",
                name: "Verify Pass",
                component: () =>
                    import ("@/components/Events/selectPass.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Verify Pass",
                },
            },
            {
                path: "program-order",
                name: "Program Payment",
                component: () =>
                    import ("@/components/Events/order.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Program Payment",
                },
            },
            {
                path: "/program-pay",
                name: "Program Payment",
                component: () =>
                    import ("@/components/Events/pay.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Program Payment",
                },
            },
            {
                path: '/chatai',
                name: 'chatai',
                component: () =>
                    import ('@/components/LearningHub/AIQuestion'),
                meta: {
                    requiresAuth: true,
                    title: 'FBLA-ECOG'
                },
            },


        ]
    },
    {
        path: '/adminhome',
        name: 'AdminHome',
        redirect: '/adminbulletin',
        component: () =>
            import ('@/views/AdminHome.vue'),
        meta: {
            requiresAuth: true
        },
        children: [{
                path: '/adminbulletin',
                name: 'Bulletin',
                component: () =>
                    import ('@/components/AdminBulletin'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/advancement',
                name: 'Advancement',
                component: () =>
                    import ('@/components/Advancement'),
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/scoreAward',
                name: 'scoreAward',
                component: () =>
                    import ('@/components/Score'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/resultrelease',
                name: 'ResultRelease',
                component: () =>
                    import ('@/components/ResultRelease'),
                meta: {
                    requiresAuth: true
                }
            },






        ]
    },


    {
        path: '/screen',
        name: 'Screen',
        component: () =>
            import ('@/components/Screen'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/videoPlay',
        name: 'VideoPlay',
        component: () =>
            import ('@/components/Student/List/videoPlay'),
        meta: {
            requiresAuth: true
        },
        props: true
    },
    {
        path: '/studentscreen',
        name: 'StudentScreen',
        component: () =>
            import ('@/components/StudentScreen'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/employeescreen',
        name: 'EmployeeScreen',
        component: () =>
            import ('@/components/EmployeeScreen'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test',
        name: 'Test',
        component: () =>
            import ('@/components/Student/Test'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/exammobile',
        name: 'ExamAdminMobile',
        component: () =>
            import ('@/components/ExamAdminMobile'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/examscreen',
        name: 'ExamAdminScreen',
        component: () =>
            import ('@/components/ExamAdminScreen'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blank',
        name: 'Blank',
        component: () =>
            import ('@/views/blank.vue'),
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        let userId = getUserId()
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router