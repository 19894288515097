import Cookies from 'js-cookie'

export function setUserId(user_id) {
    Cookies.set('wao_user_id', user_id)
}

export function getUserId() {
    return Cookies.get('wao_user_id')
}

export function removeUserId() {
    Cookies.remove('wao_user_id')
}

export function setUserName(user_name) {
    Cookies.set('wao_user_name', user_name)
}

export function getUserName() {
    return Cookies.get('wao_user_name')
}

export function removeUserName() {
    Cookies.remove('wao_user_name')
}


export function setTestEvent(event) {
    Cookies.set('test_event', event)
}

export function getTestEvent() {
    return Cookies.get('test_event')
}

export function removeTestEvent() {
    Cookies.remove('test_event')
}


export function setUserType(user_type) {
    Cookies.set('wao_user_type', user_type)
}

export function getUserType() {
    return Cookies.get('wao_user_type')
}

export function removeUserType() {
    Cookies.remove('wao_user_type')
}

export function getProjectCode() {
    let projectCode = Cookies.get('wao_project_code')
    if (projectCode) {
        return projectCode
    }
    return 'WAO'
        // return '21-22FBLAecon_china'
}

export function setProjectCode(project_code) {
    Cookies.set('wao_project_code', project_code)
}

export function getProjectName() {
    let project_name = Cookies.get('project_name')
    if (project_name) {
        return project_name
    }
    return '2024 Academic Marathon'
        // return '21-22FBLAecon_china'
}

export function setProjectName(project_name) {
    Cookies.set('project_name', project_name)
}


export function setSessionUrl(user_id) {
    Cookies.set('sessionUrl', user_id)
}

export function getSessionUrl() {
    return Cookies.get('sessionUrl')
}


export function setSessionName(user_id) {
    Cookies.set('sessionName', user_id)
}

export function getSessionName() {
    return Cookies.get('sessionName')
}


export function setLoginInfo(info) {
    Cookies.set('login_info', info)
}

export function getLoginInfo() {
    return Cookies.get('login_info')
}

export function setApplicationExtraEvents(application_extra_events) {
    Cookies.set('application_extra_events', application_extra_events)
}

export function getApplicationExtraEvents() {
    return Cookies.get('application_extra_events')
}

export function setApplicationEvents(application_events) {
    Cookies.set('application_events', application_events)
}

export function getApplicationEvents() {
    return Cookies.get('application_events')
}

export function setAppId(app_id) {
    Cookies.set('app_id', app_id)
}

export function getAppId() {
    return Cookies.get('app_id')
}


export function setAccountCountry(country) {
    Cookies.set('wao_account_country', country)
}

export function getAccountCountry() {
    return Cookies.get('wao_account_country')
}

export function setAccountContinent(continent) {
    Cookies.set('wao_account_continent', continent)
}

export function getAccountContinent() {
    return Cookies.get('wao_account_continent')
}

export function setAccountName(account_name) {
    Cookies.set('wao_account_name', account_name)
}

export function getAccountName() {
    return Cookies.get('wao_account_name')
}

export function setAvatarUrl(avatar_url) {
    Cookies.set('avatar_url', avatar_url)
}

export function getAvatarUrl() {
    return Cookies.get('avatar_url')
}

export function logout() {
    Cookies.remove('project_name')
    Cookies.remove('wao_project_code')
    removeUserId()
    removeUserType()
    removeUserName()
    Cookies.remove('application_extra_events')
    Cookies.remove('application_events')
    Cookies.remove('app_id')
    Cookies.remove('account_name')
    Cookies.remove('avatar_url')
}

export function getUserIP(onNewIP) {
    let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new MyPeerConnection({
        iceServers: []
    });
    let noop = () => {};
    let localIPs = {};
    let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    let iterateIP = (ip) => {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
    };
    pc.createDataChannel('');
    pc.createOffer().then((sdp) => {
        sdp.sdp.split('\n').forEach(function(line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
        });
        pc.setLocalDescription(sdp, noop, noop);
    }).catch((reason) => {});
    pc.onicecandidate = (ice) => {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
}