import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icon/iconfont.js'
import router from './router'
import dayjs from "dayjs";
import "./element-variables.scss";

import "tailwindcss/tailwind.css";
import "normalize.css";
import locale from "element-ui/lib/locale/lang/en";

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
//Note: 请注意：把下面代码放在tailwindcss/tailwind.css文件里面，代码位置一定要放在@tailwind base; 这行代码下方，
//因为这个会影响生成PDF证书文件里面文字显示位置
//@layer base {
//     img {
//         @apply inline-block;
//     }
// }

// import leNotes from './components/Student/letax/index.js'
// Vue.use(leNotes)
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs;
Vue.use(VueQuillEditor)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

Vue.use(ElementUI, { locale });
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')